import { iconEvent, iconTrash } from "@/design/icon/iconConst";
import { ruleNames } from "@/model/rules/ruleModel";

/**
 * Agenda Template entity Name
 * @type {string}
 */
const entityName = "Agenda Template";

/**
 * document Assembly entity Name
 * @type {string}
 */
const documentAssemblyEntityName = "Document Template";

/**
 * agenda Template Operation names
 * @type {Readonly<{delete: string, open: string}>}
 */
const agendaTemplateOperation = Object.freeze({
  select: "select",
  open: "open",
  delete: "delete"
});

/**
 * agenda Template Operations
 * @type {{name: string, icon: string, label: string}[]}
 */
const agendaTemplateOperations = Object.freeze([
  {
    name: agendaTemplateOperation.select,
    label: `Select`,
    icon: iconEvent
  },
  {
    name: agendaTemplateOperation.open,
    label: `Open`,
    icon: iconEvent
  },
  {
    name: agendaTemplateOperation.delete,
    label: `Delete`,
    icon: iconTrash
  }
]);

/**
 * find Agenda Template Operation
 * @param name Agenda Template Operation name
 * @return {{name: string, icon: string, label: string}} Agenda Template Operation
 */
const findAgendaTemplateOperation = name => {
  return agendaTemplateOperations?.find(el => el?.name === name);
};

/**
 * format Agenda Template Action Name
 * @param {string} name Agenda Template name
 * @return {string} formatted Agenda Template Action Name
 */
const formatAgendaTemplateActionName = name => {
  return `${findAgendaTemplateOperation(name)?.label} ${entityName}`;
};

/**
 * format Document Template Action Name
 * @param {string} name Document Template name
 * @return {string} formatted Document Template Action Name
 */
const formatDocumentTemplateActionName = name => {
  return `${
    findAgendaTemplateOperation(name)?.label
  } ${documentAssemblyEntityName}`;
};

const agendaItemTemplateRules = id => {
  const rules = [];

  if (id < 0) {
    rules.push(value => !!value || ruleNames.required);
  }

  return rules;
};

export {
  entityName,
  agendaTemplateOperation,
  agendaTemplateOperations,
  findAgendaTemplateOperation,
  formatAgendaTemplateActionName,
  agendaItemTemplateRules,
  documentAssemblyEntityName,
  formatDocumentTemplateActionName
};
